"use strict";
$(($) => {

  // ハンバーガーメニュー
  $('.js-hamburger').on('click', () => {
    if($(window).width() > 1200 ) return;
    $('.js-hamburger, .js-menu').toggleClass('is-open');
    $('.js-logo').toggleClass('change-logo');
    if($('body').css('overflow') === 'hidden') {
      $('body').css({ height: '', overflow: ''});
    } else {
      $('body').css({ height: '100%', overflow: 'hidden'});
    }
  });

  // -----------------------------------
  // タブ
  // -----------------------------------
  $('.js-tab').on('click', (event) => {
    $('.js-tab, .js-panel').removeClass('active');
    $(event.currentTarget).addClass('active');
    const index = $('.js-tab').index(event.currentTarget);
    $('.js-panel').eq(index).addClass('active');
  });

  //
  //
  //

  // スライドショー
  const optionFv = {
    // pagenation: false,
    arrows: true,
    autoplay: true,
    // interval: 4000,
    type: 'fade',
    rewind: true,
    // updateOnMove: true,
  }

  new Splide('.js-fv__slider', optionFv).mount();
});
